@import 'src/asset/scss/shared/color';

.formCheckReset {
  input[type=checkbox]{
    accent-color: theme-color('secondary') !important;
  }

  label {
    color: theme-color('mist');
    font-size: 14px;
    font-weight: 400;
  }
}