@import 'src/asset/scss/shared/color';

.container {
  padding-bottom: 50px;

  .title {
    color: theme-color('secondary');
    font-family: 'Bebas Neue';
    font-size: 44px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    padding-top: 3rem;
    padding-bottom: 40px;
  }

  .notYetCompletedContainer {
    h2 {
      padding-bottom: 10px;
    }
  }
}