@import 'src/asset/scss/shared/color';

.container {
  background-color: theme-color('secondary-gray');
  padding: 24px;
  border-radius: 6px;
  display: flex;
  width: 100%;
  margin: 0 auto 20px;

  .lockedContainer {
    width: 24px;
    line-height: 1.3em;
  }

  .weekInfoContainer {
    width: 100%;

    .weekHeader {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      width: 100%;
  
      .weekTitle {
        color: theme-color('white');
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.36px;
        margin: 0;
      }
  
      .weekTitleLocked {
        color: theme-color('white');
        opacity: 0.44;
      }
  
      .weekDate {
        color: theme-color('ivory');
        font-size: 18px;
        font-weight: 400;
        line-height: 1.2em;
        letter-spacing: -0.55px;
        text-transform: capitalize;
      }
  
      .weekDateLocked {
        color: theme-color('ivory');
        opacity: 0.44;
      }
    }

    .weekPills {
      display: flex;
      gap: 20px;
      margin-top: 50px;
      flex-wrap: wrap;
    }
  }
}