@import 'src/asset/scss/shared/color';

.container {
  margin-bottom: 30px;

  h3 {
    color: theme-color('ivory');
    font-family: 'Bebas Neue';
    font-size: 26px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin-bottom: 20px;
  }
}