@import 'src/asset/scss/shared/color';

.container {    
  margin-bottom: 35px;

  .profileFormGroup {
    .profileFormGroupLabel {
      color: theme-color('mist');
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }
  
    .profileFormGroupCol {
      background: theme-color('secondary-gray');
      border-radius: 6px;
  
      p {
        color: theme-color('light-gray');
        font-family: 'Bebas Neue';
        font-size: 21px;
        font-weight: 400;
        line-height: 45px;
      }

      input {
        color: theme-color('light-gray');
        font-family: 'Inter';
        font-size: 18px;
        font-weight: 700;
        line-height: 45px;
        border-radius: 0;
      }

      .isInvalidReset {
        width: 95%;
      }

      div {
        padding: 0;
  
        &:nth-child(3) {
          width: 24px;
          height: 24px;

          img {
            margin-top: 10px;
            width: inherit;
            height: inherit;
            cursor: pointer;
          }
        }
      }
    }
  }
  
  .changePasswordContainer {
    margin-top: 30px;
    padding-top: 25px;
    border-top: 1px solid theme-color('secondary-gray');

    .profileInfoBtnContainer {
      display: flex;
      justify-content: end;
      margin-top: 20px;
    
      .cancelInfoBtn {
        width: 110px;
        height: 40px;
        border-radius: 30px;
        border: 1px solid theme-color('mushroom');
        background-color: transparent;
        color: theme-color('mushroom');
        font-family: 'Bebas Neue';
        font-size: 16px;
        font-weight: 400;
        margin-right: 20px;
        box-shadow: transparent;
        transition: .3s all;
      
        &:hover {
          background-color: theme-color('mushroom');
          color: theme-color('white');
        }
      }
    }
  }

  .changePasswordBtn {
    width: 160px;
    height: 40px;
    border: 1px solid theme-color('secondary');
    border-radius: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: theme-color('secondary');
    font-family: 'Bebas Neue';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: .3s all;
    margin-top: 30px;

    &:hover {
      background-color: theme-color('secondary');
      color: theme-color('white');
    }
  }
}

.saveInfoBtn {
  width: 110px;
  height: 40px;
  border-radius: 30px;
  border: 1px solid theme-color('secondary');
  background-color: transparent;
  color: theme-color('secondary');
  font-family: 'Bebas Neue';
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: .3s all;

  &:hover {
    background-color: theme-color('secondary');
    color: theme-color('white');
  }
}