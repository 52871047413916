@import 'src/asset/scss/shared/color';

.container {
  .workoutsDetail {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    padding-bottom: 22px;
    margin-bottom: 22px;
    border-bottom: 1px solid theme-color('secondary-gray');
    
    .completedWorkouts {
      background-color: theme-color('secondary-gray');
      width: fit-content;
      height: fit-content;
      border-radius: 30px;
      padding: 8px 14px;
      display: flex;
      align-items: center;
      gap: 11px;

      span {
        color: theme-color('ivory');
        font-size: 14px;
        font-weight: 700;
        line-height: 15px;
        letter-spacing: -0.45px;

        &:last-child {
          font-size: 12px;
          font-weight: 400;
          letter-spacing: -0.5px;
        }
      }
    }

    .weekDate {
      text-transform: capitalize;
    }

    .timestampContainer {
      color: theme-color('ivory');
      text-transform: capitalize;
    }

    > div:last-child {
      max-width: fit-content;
    }

    .programmeIntensityPillContainer > div {
      width: fit-content;
    }
  }
}