@use "sass:math";

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}
// https://www.developerdrive.com/15-essential-sass-mixins/


/**
 *  div {
 *   @include box-sizing(border-box);
 *  }
 */
@mixin box-sizing($type)
{
  -webkit-box-sizing:$type;
  -moz-box-sizing:$type;
  box-sizing:$type;
}


/**
 *  div {
 *   @include opacity(0.5);
 *  }
 */
@mixin opacity($opacity) {
  opacity: $opacity;
  filter: alpha(opacity=($opacity * 100));
}



/**
 *  div {
 *   @include font-size(14px);
 *  }
 */
@mixin font-size($size) {
  font-size:$size;
  font-size: math.div($size, 16px) * 1rem;
}

/**
 *  div {
 *   @include box-shadow(8px, 8px);
 *   }
 */
@mixin box-shadow( $h: 10px , $v: 10px , $b: 0px , $s: 0px , $c: #000000 ) {
  -webkit-box-shadow: $h $v $b $s $c;
  -moz-box-shadow: $h $v $b $s $c;
  box-shadow: $h $v $b $s $c;
}

/**
 *  div {
 *   @include vertical-align();
 *   }
 */
@mixin vertical-align {
  //position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

/**
 *  div {
 *   @include horizontal-align();
 *   }
 */
@mixin horizontal-align {
  //position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

/**
 *  div {
 *   @include hv-align();
 *   }
 */
@mixin hv-align {
  //position: relative;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/**
 *  div {
 *   @include flexbox();
 *   }
 */
@mixin flexbox
{
  display:-webkit-box; // old
  display:-moz-box; // old
  display:-ms-flexbox; // ie
  display:-webkit-flex; // new
  display:flex; // new
}


/**
 *  div {
 *    @include flex(1, 2);
 *  }
 */
@mixin flex($values) {
  -webkit-box-flex: $values;
  -moz-box-flex:  $values;
  -ms-flex:  $values;
  -webkit-flex:  $values;
  flex:  $values;
}

/**
 *  div {
 *   @include flex-order(3);
 *  }
 */
@mixin flex-order($order){
  -webkit-box-ordinal-group: $order; // old
  -moz-box-ordinal-group: $order; // old
  -ms-flex-order: $order; // ie
  -webkit-order: $order; // new
  order: $order; // new
}

/**
 *  div {
 *   @include flex-direction(column);
 *  }
 */
@mixin flex-direction($direction)
{
  @if $direction == column
  {
    -webkit-flex-direction:column;
    -moz-flex-direction:vertical;
    -ms-flex-direction:column;
    -webkit-flex-direction:column;
    flex-direction:column;
  }
  @else
  {
    -webkit-flex-direction:row;
    -moz-flex-direction:horizontal;
    -ms-flex-direction:row;
    -webkit-flex-direction:row;
    flex-direction:row;
  }
}
