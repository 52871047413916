.programmeCardContainer {
  width: 100%;
  position: relative;
  border-radius: 8px;
  cursor: pointer;

  .programmeCardImageContainer {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;

    .programmeCardImage {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
      max-height: 270px;
      object-position: top;
    }
  }
  .programmeCardContent {
    z-index: 1000;
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;

    .programmeCardTitle {
      color: #ffffff;
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.32px;
      text-transform: uppercase;
      font-family: 'Bebas Neue', sans-serif;
    }
  }
}
