@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.container {

  background-color: theme-color('primary-gray');
  border-radius: 16px;


  text-align: center;

  :global(.nav-item) {
    height: 70px;
    padding-top: 15px;
    border-bottom-style: solid;
    border-bottom-color: theme-color('secondary-gray');
    border-bottom-width: 1px;

    &:last-child {
      border-bottom-style: none;
    }

    :global(.nav-link) {
      color: theme-color('white');
      font-family: Evogria;
      @include font-size(20px);
    }

    &.active {
      :global(.nav-link) {
        color: theme-color('primary');

      }
    }
  }

}
