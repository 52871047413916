@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';
.filterButtonContainer {
  padding: 0 13px;

  .filterButton {
    width: 120px;
    height: 2.1rem;
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
  }
}
