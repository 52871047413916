@import 'src/asset/scss/shared/color';

.modal {
  z-index: 2147483647;
  background-color: $body-bg;
  position: fixed;
  padding:100px;
  top:0;
  bottom:0;
  left:0;
  right:0;
  text-align: center;
}
