@import 'src/asset/scss/shared/color';

.container {
  .title {
    color: theme-color('secondary');
    font-family: 'Bebas Neue';
    font-size: 44px;
    font-weight: 400;
    text-transform: uppercase;
  }

  .notFound {
    color: theme-color('white');
  }
}