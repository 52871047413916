@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.todaysWorkoutSlideContainer {
  position: relative;
  width: 100%;
  height: 700px;
  max-height: 100vh;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: pointer;

  @media screen and (max-width: 767px) {
    height: 450px;
  }

  .todaysWorkoutSlideImage {
    width: 100%;
    height: 100%;
    background-position: top center;
    background-size: cover;
    position: absolute;
    z-index: 80;
    top: 0;
    left: 0;
    border-radius: 8px;
  }

  .slideContent {
    position: relative;
    z-index: 100;
    width: 100%;
    height: 100%;
    min-height: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 86px;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 30px 40px;
    color: theme-color('white');

    @media screen and (max-width: 767px) {
      padding: 0 0 50px;
    }

    .slideTitle {
      text-align: center;
      font-size: 88px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      font-family: 'Bebas Neue';
      justify-self: center;
      align-self: center;

      @media screen and (max-width: 767px) {
        font-size: 55px;
        line-height: 1em;
      }
    }
    .workoutInfo {
      justify-self: flex-end;
      width: 100%;

      h3 {
        font-family: 'Bebas Neue';
        font-size: 44px;
        font-weight: 400;
        letter-spacing: 1.32px;
        text-transform: uppercase;

        @media screen and (max-width: 767px) {
          font-size: 35px;
          line-height: 20px;
        }
      }

      h4 {
        color: theme-color('white');
        font-size: 21px;
        font-weight: 700;
        margin-top: 6px;
      }
    }
  }
}

.todaysWorkoutsContainer {
  width: 100%;
  max-height: 100vh;
  box-sizing: border-box;
  border-radius: 8px;

  a, a:hover {
    text-decoration: none;
  }
}

.noWorkouts {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;

  letter-spacing: -0.03em;
  text-transform: uppercase;
  text-align: center;

  color: #FFFFFF;
}

.carousel {
  max-width: 1140px;
  margin: 0 auto;
}