@import 'src/asset/scss/shared/color';

.container {
  min-width: fit-content;
  height: 31px;
  border-radius: 6px;
  border: 1px solid theme-color('secondary-gray');
  display: flex;
  align-items: center;
  padding: 0 10px;
  gap: 9px;
  color: theme-color('ivory');
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.36px;
}