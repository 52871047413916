@import 'src/asset/scss/shared/color';

.title {
  text-align: center;
  color: theme-color('secondary');
  font-family: 'Bebas Neue';
  font-size: 66px;
  font-weight: 400;
  text-transform: uppercase;
}

.noWorkoutsLabel {
  font-size: 44px;
  font-weight: 400;
  font-family: "Bebas Neue";
  color: theme-color('white');
}  