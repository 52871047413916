@import 'src/asset/scss/shared/util';
@import 'src/asset/scss/shared/color';

.ProgrammeIntensityPill {
  display: flex;
  flex-direction: row;
  max-width: 169px;
  height: 30px;
  gap: 9px;
  box-sizing: border-box;
  padding: 6px 14px;
  align-items: center;
  border-radius: 111px;
  flex: 1;

  .intensityIcon {
    width: 17px;
    height: 16px;
    object-fit: fill;
  }

  &.low {
    background-color: theme-color('green-08');
  }
  
  &.medium {
    background-color: theme-color('secondary-08');
  }

  &.high {
    background-color: theme-color('danger-08');
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 14px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.42px;
    text-transform: capitalize;
  }
}



