@import 'src/asset/scss/shared/color';

.container {
  margin-bottom: 30px;

  .card {
    background: theme-color('secondary-gray');
    color: theme-color('white');
    line-height: 30px;
    border-radius: 10px;
    margin-top: 10px;

    .alert {
      background-color: theme-color('secondary');
      color: theme-color('secondary-gray');
      border: none;
    }
  }
}