@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.title {
  color: theme-color('primary');
  font-family: Evogria;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  align-items: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
}

.description {
  font-family: GT Walsheim Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  color: theme-color('ivory');
}

