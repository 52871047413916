@import './shared/color';
@import '../../../node_modules/bootstrap/scss/bootstrap';
@import './shared/util';

/********************************************************
 ***                      FONT                        ***
 ********************************************************/

@font-face {
  font-family: 'Evogria';
  src: url(../font/Evogria.otf) format('opentype');
}

@font-face {
  font-family: 'Evogria';
  src: url(../font/Evogria-Italic.otf) format('opentype');
  font-style: italic;
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url(../font/GTWalsheimPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'GT Walsheim Pro';
  src: url('../font/GT Walsheim Pro-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Bebas Neue';
  src: url(../font/BebasNeue-Regular.ttf) format('opentype');
}

@font-face {
  font-family: 'Inter';
  src: url(../font/Inter-VariableFont.ttf) format('opentype');
}

* {
  font-family: 'Inter';
}

/********************************************************
 ***                      TEXT                        ***
 ********************************************************/

h1 {
  font-family: 'Bebas Neue';

  @include font-size(34px);
}

/********************************************************
 ***                     CONTAINER                     ***
 ********************************************************/

//$grid-breakpoints:( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px, xxl: 1920px );
//$container-max-widths:   ( sm: 540px, md: 720px, lg: 960px, xl: 1200px, xxl: 1918px );
//$colors: ( blue: #1799d3, pink: #e20073 );
//$theme-colors: ( primary: #1799d3, pink: #e20073 ); $body-color: #444; $line-height-base: 1.2;

nav.main-top-nave {
  $nav-height: 104px;
  background-color: theme-color('primary-gray');
  height: $nav-height;

  &.navbar {
    padding: 0;
  }

  .navbar-collapse .navbar-nav {
    > a,
    .dropdown > a {
      background-color: theme-color('primary-gray');
    }
  }

  &::before {
    position: fixed;
    z-index: -1;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    background-color: inherit;
    //background-color: theme-color('primary-gray');
    display: block;
    width: 100%;
    height: $nav-height;
  }

  .navbar-brand {
    position: relative;
    border-right-style: solid;
    border-right-width: 1px;
    border-right-color: theme-color('secondary-gray');
    height: $nav-height;
    padding-top: 25px;
    padding-right: 20px;
  }
}

.profile-top-margin {
  margin-top: 88px;
}

/********************************************************
 ***                      PANEL                       ***
 ********************************************************/
.panel {
  //margin: 10px;

  &.panel-radius {
    border-radius: 24px;
  }
}

/********************************************************
 ***                      TEXT                        ***
 ********************************************************/

h1 {
  font-family: Evogria;

  @include font-size(34px);
}

/********************************************************
 ***                     BUTTON                       ***
 ********************************************************/

.btn {
  font-family: Evogria;
  text-transform: uppercase;

  border-radius: 125px;
  height: 64px;
  margin-bottom: 20px;

  @include font-size(24px);

  padding-left: 25px;
  padding-right: 25px;

  &-primary {
    align-items: center;
    text-align: center;
  }

  &-link {
    font-family: 'GT Walsheim Pro';
    @include font-size(14px);
    text-transform: none;
    background-color: transparent;
    border-style: none;

    &:hover,
    &:active,
    &:focus {
      background-color: transparent;
      color: var(--primary);
    }
  }
  &-small {

    border-radius: 50px;
    height: 32px;
    margin-bottom: 10px;
    @include font-size(12px);

    padding-left: 12px;
    padding-right: 12px;

  }
}

/********************************************************
 ***                     INPUT                        ***
 ********************************************************/

// Hide Arrows From Input Number
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

//Hide Arrows From Input Number
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/********************************************************
 ***                      FORM                        ***
 ********************************************************/

.form-control {
  input {
    font-family: 'GT Walsheim Pro';
  }

  color: theme-color('mist');

  &:focus {
    background-color: theme-color('secondary-gray');
    color: theme-color('mist');
    box-shadow: none;
  }

  background-color: theme-color('secondary-gray');
  border-radius: 24px;
  border-style: none;
  padding-left: 25px;
  padding-right: 25px;
  height: 72px;

  @include placeholder {
    color: theme-color('mushroom');
  }

  &.readonly {
    background-color: transparent;
    color: theme-color('mushroom');
    padding-top: 24px;
  }
}

.form-group {
  .hint,
  .invalid-feedback {
    padding-left: 25px;
    padding-right: 25px;
    @include font-size(14px);
  }

  padding-top: 10px;
  padding-bottom: 10px;

  .form-label.col-form-label {
    margin-top: 15px;
  }

  .underline {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: theme-color('secondary-gray');
  }

  &.thin {
    //.form-label.col-form-label {
    //  margin-top: 0;
    //}
    .form-control {
      height: 40px;
    }
  }
}

/********************************************************
 ***                  DATE-PICKER                     ***
 ********************************************************/

.react-datepicker__close-icon::after {
  background-color: theme-color('mushroom');
}

/********************************************************
 ***                      TAB                         ***
 ********************************************************/

.nav.grnd-tab {
  margin-top: 40px;

  .nav-item {
    $nav-margin: 20px;

    &:first-child {
      margin-right: $nav-margin;
    }

    &:last-child {
      margin-left: $nav-margin;
    }

    &:not(:last-child):not(:first-child) {
      margin: 0 $nav-margin*0.5;
    }

    a {
      border-bottom-style: solid;

      &.active {
        border-bottom-color: theme-color('primary');
        color: theme-color('white');
      }
    }

    color: theme-color('tertiary-grey');

    a {
      @include font-size(20px);
      color: theme-color('tertiary-grey');
      font-weight: bold;
    }
  }
}

.programmeNavItem {
  a {
    color: theme-color('tertiary-grey');
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.28px;
    border-bottom-style: solid;

    &.active {
      color: theme-color('white');
      border-bottom-color: theme-color('primary');
    }
  }

  &:not(:last-child) {
    margin-right: 4px;
  }
}

/********************************************************
 ***                 MARKDOWN TABLE                   ***
 ********************************************************/

.markdown-table {
  td, th {
    border: 1px solid theme-color('primary-gray');
  }
}
