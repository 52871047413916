@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.educationHeader {
  position: relative;
  height: 500px;
  border-radius: 8px;

  .educationImage {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 80;
    object-fit: cover;
    object-position: top left;
    border-radius: 8px;
  }
  
  .educationHeaderContent {
    z-index: 100;
    width: 100%;
    height: 100%;
    color: theme-color('white');
    border-radius: 8px;

    h1 {
      font-family: 'Bebas Neue';
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.32px;
      text-transform: uppercase;
    }

    p {
      color: theme-color('ivory');
      font-family: 'Inter';
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}
