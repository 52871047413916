@import 'src/asset/scss/shared/color';

.newBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background-color: #ffa057c4;
  height: 34px;
  text-align: center;
  font-family: 'Bebas Neue';
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  border-radius: 111px;
  width: fit-content;
  padding: 8px 16px;
  margin-bottom: 10px;
}