@import '../../../node_modules/bootstrap/scss/bootstrap';
@import 'src/asset/scss/shared/color';

.navbarToggleIcon {
  background-color: theme-color('secondary') !important;
}

@include media-breakpoint-down(md) {
  nav:global(.main-top-nave).halfSizeNavSM {
    $nav-height: 52px;
    height: $nav-height;

    &::before {
      height: $nav-height;
    }

    :global(.navbar-brand) {
      height: $nav-height;
      padding-top: 12px;
      svg{
        width:109px;
        height:28px;
      }
    }

  }
}

.favoriteButtonContainer {
  height: 2.1rem;
  .favoriteButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: fit-content;
    border-radius: 6px;
    padding: 0 8px;
  
    .heartIcon {
      height: 15px;
      width: 19px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

