@import 'src/asset/scss/shared/color';

.container {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 10px;

  .title {
    font-size: 15px;
    letter-spacing: -0.3px;
    color: theme-color('mushroom');
  }

  .value {
    font-size: 14px;
    color: theme-color('white');
  }
}
