@import 'src/asset/scss/shared/util';
@import 'src/asset/scss/shared/color';

.bio {
  margin-top: 56px;
  p {
    color: theme-color('ivory');
    line-height: 28.8px;
  }
}
