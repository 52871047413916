@import 'src/asset/scss/shared/util';
@import 'src/asset/scss/shared/color';

.container {
  background-color: $body-bg;
}

.card {
  background-color: theme-color('primary-gray') !important;
  margin-bottom: 16px !important;
  border-radius: 16px !important;
}
