@import 'src/asset/scss/shared/color';

.container {
  display: flex;
  flex-direction: column;
  //gap: 14px;
  cursor: pointer;
}

.WorkoutCarouselCardWrapper {
  position: relative;
  border-radius: 6px;
  width: 100%;
  //aspect-ratio : 1 / 1;
  box-sizing: border-box;


  .checkmarkContainer {
    position: absolute;
    top: 11px;
    right: 13px;
  }

  .cardImage {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-size: cover;
    background-position: top center;
  }

  .cardContent {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 6px;
    padding: 22px 20px 19px 22px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: theme-color('ivory');

    .cardDate {
      font-family: 'Inter';
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
    }

    .cardBottom {
      display: flex;
      flex-direction: column;
      width: 100%;

      h3 {
        font-family: 'Bebas Neue';
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 1.32px;
        text-transform: uppercase;
      }

      .cardFooter {
        display: flex;
        justify-content: space-between;

        .trainerName {
          font-family: 'Inter';
          font-size: 21px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }
    }
  }
}
