@import 'src/asset/scss/shared/color';

.modal {
  height: fit-content;
  padding: 30px 0;
  background-color: theme-color('secondary-gray');
  border: 2px solid theme-color('bright-grey');

  .awardImage {
    display: block;
    width: 200px;
    margin: 0 auto;
  }

  h4 {
    text-align: center;
    color: theme-color('primary');
    text-transform: uppercase;
    font-weight: 400;
    padding-top: 20px;
  }

  h5 {
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
  }
}