.container {
  margin-top: 30px;

  .dateContainer {
    color: theme-color('ivory');
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 30px;
  }

  .notFound {
    color: theme-color('white');
  }
}