@import 'src/asset/scss/shared/util';
@import 'src/asset/scss/shared/color';

$bottom-padding: 40px;

.jumbotron {
  overflow: hidden;
  position: relative;
  padding-top: 50px;
  max-width: 600px;
  padding-bottom: $bottom-padding;

  .profileImage {
    width: 100%;
  }


  h1 {
    @include font-size(24px);
    position: absolute;
    bottom: $bottom-padding + 26px;
    left: 16px;
  }

  .instagramIcon {
    position: absolute;
    bottom: $bottom-padding + 26px;
    right: 16px;
    width: 32px;
    height: 32px;
  }
}
