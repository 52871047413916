@import 'src/asset/scss/shared/util';
@import 'src/asset/scss/shared/color';

.modal {
  background-color: $body-bg !important;
  margin-bottom: 16px !important;
  border-radius: 16px !important;
  border-color: theme-color("primary") !important;
  border-top-width: 2px !important;
}

.footer {
  border-top-width: 0 !important;
}

.logoutBtn {
  width: 160px;
  height: 40px;
  border-radius: 30px;
  border: 1px solid theme-color('danger');
  color: theme-color('ivory');
  font-family: 'Bebas Neue';
  font-size: 16px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .3s all;
  cursor: pointer;
  margin-bottom: 40px;

  &:hover {
    background-color: theme-color('danger');
  }
}
