@import 'src/asset/scss/shared/color';

.workoutCard {
  border-radius: 6px;
  position: relative;
  cursor: pointer;

  .workoutCardImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top center;
    border-radius: 6px;
  }

  .workoutCardContent {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    top: 0;
    left: 0;
    color: theme-color('ivory');
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .workoutCardTitle {
      font-family: 'Bebas Neue';
      font-size: 32px;
      font-weight: 400;
      line-height: 80%;
      letter-spacing: 0.96px;
      text-transform: uppercase;
    }

    .workoutCardFooter {
      display: flex;
      justify-content: space-between;
      .trainerName {
        font-family: 'Inter';
        font-size: 21px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }
}

@media (max-width: 991px) {
  .smallCard,
  .largeCard {
    height: auto !important;
  }
}

@media (min-width: 992px) {
  .smallCard {
    height: 199px !important;
  }

  .largeCard {
    height: calc(2 * 199px + 0.5rem) !important;
  }
}

.checkmarkContainer {
  position: absolute;
  top: 11px;
  right: 13px;
}
