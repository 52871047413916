@import 'src/asset/scss/shared/color';

.container {
  border-radius: 14px !important;
  background-color: theme-color('secondary-gray') !important;

  .membershipCardImage {
    height: 410px;
    object-fit: cover;
    border-radius: 12px 12px 0 0;
  }

  h3 {
    color: theme-color('light-gray');
    font-family: 'Bebas Neue';
    font-size: 32px;
    font-weight: 400;
  }

  h4 {
    color: theme-color('mist');
    font-size: 18px;
    font-weight: 500;
  }

  .membershipCardBtn {
    width: 130px;
    height: 40px;
    border-radius: 30px;
    border: 1px solid theme-color('secondary');
    display: flex;
    justify-content: center;
    align-items: center;
    color: theme-color('secondary');
    font-family: 'Bebas Neue';
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
    transition: .3s all;

    &:hover {
      background-color: theme-color('secondary');
      color: theme-color('white');
    }
  }
}