@import 'src/asset/scss/shared/color';

.coverImage {
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
  max-height: 450px;
  height: 100%;
  object-position: top;

  @media screen and (min-width: 1200px) {
    max-height: 340px;
  }
}

.titleContainer {
  margin-top: 26px;
  margin-bottom: 14px;

  h2 {
    font-size: 44px;
    font-family: 'Bebas Neue';
    color: theme-color('white');
    font-weight: 400;
    letter-spacing: 1.35px;
    text-transform: uppercase;
    margin: 0;
  }

  .time {
    font-size: 14px;
    color: theme-color('ivory');
    font-weight: 400;
    line-height: 15px;
    letter-spacing: -0.45px;
    text-transform: capitalize;
    padding: 15px 0;
  }
}

.topDetails {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.description {
  padding: 30px 0;
  color: theme-color('mist');
  font-size: 13px;
  font-weight: 300;
  line-height: 20px;
  border-bottom: 1px solid theme-color('secondary-gray');
}

.bottomDetails {
  margin-top: 30px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .detailsTitle {
    color: theme-color('ivory');
    font-size: 14px;
    line-height: 18px;
    font-weight: 500;
  }

  .detailsDescription {
    color: theme-color('ivory');
    font-size: 14px;
    line-height: 18px;
    font-weight: 300;
  }

  .detailsContainer {
    margin-top: 32px;
  }
}
