@import 'src/asset/scss/shared/color';

.container {
  background-color: theme-color('secondary-gray');
  border-radius: 6px;
  padding: 8px;
  margin-bottom: 20px;
  cursor: pointer;

  .imageBox {
    position: relative;
    width: 40%;


    .thumbnail {
      width: 100%;
      height: 175px;
      border-radius: 6px;
      object-fit: cover;
    }

    .thumbnailOpacity {
      opacity: 0.5;
    }

    .lockedWorkout {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 50;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .workoutTitle {
      color: theme-color('ivory');
      font-size: 44px;
      font-weight: 400;
      line-height: 52px;
      letter-spacing: 1.35px;
      text-transform: uppercase;
      font-family: 'Bebas Neue';
    }

    .trainerName {
      color: theme-color('ivory');
      font-size: 21px;
      font-weight: 700;
      letter-spacing: -0.65px;
      padding: 0 !important;
      margin: 0 !important;
    }

    .workoutDuration {
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: -0.45px;
      color: theme-color('white');
      background-color: rgba(224, 224, 224, 0.22);
      margin-left: 10px;
      padding: 7px 11px;
      border-radius: 6px;
    }
  }
}


.isLocked {
  pointer-events: none;
}

@import 'src/asset/scss/shared/util';

.educationHeader {
  position: relative;
  height: 500px;
  border-radius: 8px;

  .educationImage {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 80;
    object-fit: cover;
    object-position: top left;
    border-radius: 8px;
  }

  .educationHeaderContent {
    z-index: 100;
    width: 100%;
    height: 100%;
    color: theme-color('white');
    border-radius: 8px;

    h1 {
      font-family: 'Bebas Neue';
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.32px;
      text-transform: uppercase;
    }

    p {
      color: theme-color('ivory');
      font-family: 'Inter';
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
}

.smallWorkoutCardContainer {
  background-color: theme-color('secondary-gray');
  color: theme-color('ivory');
  width: 100%;
  height: 100%;
  flex: 1;
  border-radius: 6px;

  .smallWorkoutCardImage {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 6px;
  }

  h3 {
    font-family: 'Bebas Neue';
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.96px;
    text-transform: uppercase;
  }

  .trainerName {
    font-family: 'Inter';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .cardInfo {
    display: flex;
    gap: 1rem;
    align-items: baseline;

    .cardDate {
      text-align: right;
      font-family: 'Inter';
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &:hover {
    color: theme-color('ivory');
    text-decoration: none;
    cursor: pointer;

    h3 {
      text-decoration: underline;
    }
  }
}

.checkmarkContainer {
  position: absolute;
  top: 11px;
  right: 13px;
}
