.programmeInfoPill {
  display: flex;
  border-radius: 6px;
  opacity: 0.8;
  background: var(--mist, #E0E0E0);
  padding: 0px 8px 0px 10px;
  justify-content: center;
  align-items: center;
  height: 30px;
  flex-direction: row;

  .programmeInfoPillText {
    margin: 0;
    padding: 0;
    color: var(--primary-grey, #1F1F1F);
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.42px;
  }
}