@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.filterSection {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  padding: 30px 40px;

  @media screen and (max-width: 767px) {
    padding: 20px;
  }
}
.withSeparator {
  border-bottom: 1px solid theme-color('secondary-gray');
}
