@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.container {
  margin-top: 4rem;
}

.itemRow {
  margin-bottom: 4rem;
}
.thumbnail {
  width: 100%;
}
.durationIcon {
  position: relative;
  top: -3.5rem;
  left: 0.5rem;
  height: 0;
}
.titleText {
  font-family: Evogria;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;

  letter-spacing: -0.03em;
  text-transform: uppercase;

  color: #FFFFFF;
}

.trainerText {
  font-family: GT Walsheim Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;

  color: theme-color('primary');
}

.workoutDetailsText {
  font-family: GT Walsheim Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;

  letter-spacing: -0.03em;
  color: theme-color('mushroom');
}

.noWorkouts {
  font-family: Evogria;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;

  letter-spacing: -0.03em;
  text-transform: uppercase;

  color: #FFFFFF;
  margin-bottom: 3rem;
}

.pointer {
  cursor: pointer;
}
