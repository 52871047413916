@import 'src/asset/scss/shared/util';
@import 'src/asset/scss/shared/color';

.modal {
  background-color: $body-bg !important;
  margin-bottom: 16px !important;
  border-radius: 16px !important;
  border-color: theme-color("primary") !important;
  border-top-width: 2px !important;
}
.modalBody {
  padding: 0.5rem !important;
}
.modalDialog {
  max-width: 620px;
}

.footer {
  border-top-width: 0 !important;
  display: flex;
  justify-content: center !important;
  padding: 28px 0 40px;

  .modalButton {
    text-align: center;
    font-family: 'Bebas Neue';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
    padding: 15px 20px;
    border-radius: 125px;
    height: 60px;
    width: 250px;

    &:hover {
      border: none;
    }

  }
}

.ProgrammePreviewModalBody {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 300px;

  .programmeModalHeader {
    width: 100%;
    position: relative;

    .programmeModalHeaderImage {
      border-radius: 8px;
      width: 100%;
      object-fit: cover;
      z-index: 1000;
    }

    .programmeHeaderContent {
      position: absolute;
      bottom: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      justify-content: flex-end;
      padding: 0.5rem;
      z-index: 1500;

      .programmeCardTitle {
        color: #ffffff;
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.32px;
        text-transform: uppercase;
        font-family: 'Bebas Neue', sans-serif;
      }

      .programmeInfo {
        display: flex;
        width: 100%;
        flex-direction: row;
        gap: 12px;
      }
    }
  }
}

.programmeModalContent {
  display: flex;
  flex-direction: column;
  width: 100%;

  p.subtitle {
    color: #F2F1EC;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin: 0;
    padding: 0;
  }

  p.description {
    color: #F2F1EC;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    margin: 0;
    padding: 0;
  }

  .basicInfo {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 20px 21px 36px 24px;

    .infoBlocksContainer {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 24px;

      .infoBlock {
        display: flex;
        flex-direction: column;
        gap: 8px;
      }
    }
  }

  .averageDuration {
    display: flex;
    height: 26px;
    gap: 8px;
    padding: 6px 4px 6px 9px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    border: 1px solid #c4c4c466;

    .durationIcon {
      width: 18px;
      height: 18px;
    }

    p {
      width: fit-content;
      margin: 0;
      padding: 0;
      color: theme-color('ivory');
      font-family: 'Inter';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%;
      letter-spacing: -0.36px;
      white-space: nowrap;
    }
  }
}

.tabContainer {
  display: flex;
  width: 100%;
  padding: 21px 21px 36px;
  border-bottom: 1px solid theme-color('secondary-gray');

  p {
    color: #F2F1EC;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: 154%;
    margin: 0;
    padding: 0;
  }
}
