@import 'src/asset/scss/shared/util';
@import 'src/asset/scss/shared/color';

$avatar-size-s: 28px;
$avatar-size-m: 97px;

.container {
  &.s {
    width: $avatar-size-s;
    height: $avatar-size-s;

    .initials {
      line-height: $avatar-size-s;
      font-size: 14px;
      color: theme-color('black');
    }
  }

  &.m {
    width: $avatar-size-m;
    height: $avatar-size-m;

    .initials {
      line-height: $avatar-size-m;
      font-size: 40px;
      color: theme-color('black');
    }
  }

  .initialsContainer {
    background-color: theme-color('secondary');
    height: 100%;
    width: 100%;
    text-align: center;
    position: relative;
    border-radius: 4px;
  }
}