@import 'src/asset/scss/shared/color';

.container {
  background-color: theme-color('white');
  color: theme-color('primary-gray');
  padding: 2px 5px;
  border-radius: 30px;
  font-size: 11px;
  font-weight: 500;
  line-height: 15px;
  letter-spacing: -0.35px;
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
}