@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.videoPlayer {
  max-height: 720px;
}

.modal {
  background: theme-color('primary-gray') !important;
  opacity: 0.9;
  border-radius: 12px;
  padding: 48px
}
.modalTitle {
  font-family: Evogria;
  font-style: italic;
  font-weight: normal;
  font-size: 36px;
  line-height: 45px;

  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.modalText {
  font-family: GT Walsheim Pro;
  font-style: italic;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.03em;

  /* Mist */
  color: theme-color('mist');
  margin-bottom: 42px;
}
.workoutTitle {
  margin-top: 16px;
  font-family: Evogria;
  font-style: italic;
  font-weight: normal;
  font-size: 24px;
  line-height: 30px;

  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
}
