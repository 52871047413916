@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.container {
  //width: 208px;
  //height: 48px;
}
.avatar {
  border: 2px solid #565656;
  box-sizing: border-box;
  width: 48px;
}

.actions {
  float: right !important;
}

.trainerName {
  color: theme-color('primary');
  font-family: Evogria;
  font-style: italic;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;

  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: -0.03em;
  text-transform: uppercase;
}

.trainerProfileLink {
  color: theme-color('mist');

  font-family: GT Walsheim Pro;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;

  /* identical to box height */
  display: flex;
  align-items: center;
  text-align: right;
}
