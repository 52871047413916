@import 'src/asset/scss/shared/color';

.container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-inline: 14px;

    &.active {
    .title,
    .itemImage svg path {
      color: theme-color('secondary');
      stroke: theme-color('secondary');
    }
  }

  .title {
    color: theme-color('ivory');
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
  }
}

.navDropDown {
  :global(.dropdown-toggle)::after {
    content: none;
  }

  :global(.dropdown-menu){
    background-color: theme-color('secondary-gray');
    padding: 0;
    border-radius: 8px;
    left: -66px;
    top: 88px;

    :global(.nav-link){
      border-bottom-style: solid;
      border-bottom-color: theme-color('tertiary-grey');
      border-bottom-width: 1px;

      &:last-child{
        border-bottom-style: none;
      }

      padding:0 !important;
      //height: 50px;
    }
  }
}