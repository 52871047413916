@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.trainingSeriesCardContainer {
  width: 100%;
  position: relative;
  border-radius: 8px;
  height: 100%;

  .trainingSeriesCardImageContainer {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    border-radius: 8px;
    .trainingSeriesCardImage {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
      object-position: top left;
    }
  }
  .lockedImageContainer {
    width: 100%;
    height: 100%;
    z-index: 1019;
    position: absolute;
    background-color: gray;
    opacity: 0.7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .trainingSeriesCardContent {
    z-index: 1000;
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-end;
    padding: 20px;

    h3 {
      color: theme-color('ivory');
      font-family: 'Bebas Neue';
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: 55%;
      letter-spacing: 1.32px;
      text-transform: uppercase;
    }

    p {
      color: theme-color('ivory');
      font-family: 'Inter';
      font-size: 21px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
