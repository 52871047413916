@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.filterItemButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px 5px;
  border-radius: 6px;
  background-color: theme-color('secondary-gray');
  font-size: 13px;
  font-weight: 400;
  letter-spacing: -0.39px;
  cursor: pointer;
}
.fullWidth {
  width: 100%;
}
.filterItemActiveButton {
  color: theme-color('white');
  background-color: theme-color('secondary');
  font-weight: 600;
}
