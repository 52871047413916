@import 'src/asset/scss/shared/color';

.container {
  color: theme-color('ivory');
  font-size: 14px;
  font-weight: 700;
  cursor: pointer;

  &:hover {
    color: theme-color('ivory');
    text-decoration: none;
  }
}
