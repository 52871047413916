@import 'src/asset/scss/shared/util';

.overlaySpinner {
  position: absolute;
  background-color: #FFFFFF80;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  @include flexbox();

  justify-content: center;
  align-items: center;
  z-index: 1000;
}
