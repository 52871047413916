@import 'src/asset/scss/shared/color';

.newWorkoutsContainer {
  background-color: theme-color('ivory');
  color: theme-color('black');
  width: 100%;
  padding-top: 40px;
  padding-bottom: 50px;

  .title {
    font-family: 'Bebas Neue';
    font-size: 66px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
}

