@import 'src/asset/scss/shared/color';

.container {
  .dateContainer {
    color: theme-color('ivory');
    font-size: 21px;
    font-weight: 400;
    line-height: 1.2em;
    letter-spacing: -0.65px;
    text-align: center;
    margin-bottom: 27px;
    display: flex;
    justify-content: center;
    gap: 10px;
    text-transform: capitalize;

    span {
      font-weight: 700;
    }
  }

  .progressBar {
    padding: 40px 0;
    border-top: 1px solid theme-color('secondary-gray');
    border-bottom: 1px solid theme-color('secondary-gray');

    h4 {
      color: theme-color('secondary');
      text-align: center;
      font-size: 44px;
      font-family: 'Bebas Neue';
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 45px;
    }

    .progressBarContainer {
      max-width: 487px;
      width: 100%;
      height: 41px;
      margin: 0 auto;
      position: relative;

      .progressBorders {
        width: calc(100% / 3);
        height: 41px;
        border-left: 1px solid theme-color('tertiary-grey');
        border-right: 1px solid theme-color('tertiary-grey');
        position: absolute;
        top: -17.5px;
        left: 0;
        right: 0;
        margin-inline: auto;
        z-index: -1;  
      }

      .progressFullLine {
        max-width: 487px;
        width: 100%;
        height: 6px;
        background: theme-color('tertiary-grey');
        border-radius: 10px;
        z-index: 1;

        .progressCompletedLine {
          height: 6px;
          background-color: theme-color('secondary');
          border-radius: 10px;
        }
      }
    }
  }
}