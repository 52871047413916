@import 'src/asset/scss/shared/util';
@import 'src/asset/scss/shared/color';

.container {
  display: inline-block;

  :global(.active) svg rect:first-child {
    stroke: theme-color('primary');
  }

  :global(.active) image {
    tint: theme-color('primary');
  }
}
