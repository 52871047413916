@import "node_modules/bootstrap/scss/functions";

$theme-colors: (
  "primary": #C76633,
  "secondary": #FFA057,

  "primary-gray": #1F1F1F,
  "secondary-gray": #2F2F2F,
  "tertiary-grey": #565656,
  "bright-grey": #50555C,
  "light-gray": #C4C4C4,

  "mushroom": #8B8A85,
  "mist": #E0E0E0,
  "ivory": #F2F1EC,

  "white": #FFFFFF,
  "black": #000000,

  "danger": #F11A1A,
  "danger-02": #ca0100,

  "secondary-08": #ffa057cc,
  "danger-08": #f11a1acc,
  "green-08": #00b41ecc,

  "green-09": #07b41e,

  "bronze": #D48053,
  "gold": #A9883B
);

$body-bg: #000;
$body-color: #FFF;
