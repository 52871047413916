.container {
  position: relative;


  .showHideButton {
    position: absolute;
    right:0;
    top:0;
    height: 72px;
    width: 72px;
    padding: 15px 10px;
  }
  img{
    width: 42px;
    height: 42px;
  }
}
