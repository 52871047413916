.largeScreenCol {
  @media (min-width: 992px) {
    flex: 0 0 auto !important;
    width: 20% !important;
  }
}

.trainerCard {
  width: 187px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 35px;

  &:hover {
    text-decoration: none;
  } 

  .trainerImage {
    width: 187px;
    height: 187px;
    border-radius: 100px;
    object-fit: cover;
    object-position: center center;
  }

  .trainerName {
    color: #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 21px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -0.63px;
  }
}

.trainersSectionContainer {
  background-color: theme-color('black');
  color: theme-color('ivory');
  width: 100%;
  padding-top: 40px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  gap: 71px;

  .title {
    color: #FFA057;
    text-align: center;
    font-family: 'Bebas Neue';
    font-size: 66px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
}