@import 'src/asset/scss/shared/color';

.container {
  display: flex;
  gap: 6px;
  margin-bottom: 30px;
  padding: 0;
  align-items: end;

  .containerItem {
    width: 100%;
    
    .infoTabItemContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 8px;
      padding-bottom: 10px;
      border-bottom: 2px solid theme-color('tertiary-grey');
      color: theme-color('tertiary-grey');
      cursor: pointer;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.3px;
    }
  
    .activeTab {
      border-bottom-color: theme-color('primary');
      color: theme-color('white');
    }

    .isLocked {
      pointer-events: none;
    }
  }
}