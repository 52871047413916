@import 'src/asset/scss/shared/color';

.container {
  margin-bottom: 50px;

  h2 {
    color: theme-color('secondary');
    font-family: 'Bebas Neue';
    font-size: 44px;
    font-weight: 400;
  }

  .manageBtn {
    width: 110px;
    height: 40px;
    border-radius: 30px;
    border: 1px solid theme-color('secondary');
    background-color: transparent;
    color: theme-color('secondary');
    font-family: 'Bebas Neue';
    font-size: 16px;
    font-weight: 400;
    transition: .3s all;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  
    &:hover {
      background-color: theme-color('secondary');
      color: theme-color('white');
    }
  }
}