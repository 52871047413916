@import 'src/asset/scss/shared/color';

.trainingSeriesListContainer {
  width: 100%;
  box-sizing: border-box;
}

.noTrainingSeries {
  font-family: 'Bebas Neue';
  color: theme-color('black');
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
}

.trainingSeriesCol {
  height: 335px;
}
