@import 'src/asset/scss/shared/color';

.container {
  .mainContainer {
    justify-content: space-between;

    .title {
      color: theme-color('secondary');
      font-family: 'Bebas Neue';
      font-size: 44px;
      font-weight: 400;
    }
  }

  .logoutBtn {
    width: 160px;
    height: 40px;
    border-radius: 30px;
    border: 1px solid theme-color('danger');
    color: theme-color('ivory');
    font-family: 'Bebas Neue';
    font-size: 16px;
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: .3s all;
    cursor: pointer;
    margin-bottom: 40px;

    &:hover {
      background-color: theme-color('danger');
    }
  }
}