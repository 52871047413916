@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.filterItemsContainer {
  display: flex;
  justify-content: space-between;
  gap: 0.40rem;
}

.padElement {
  visibility: hidden;
}
