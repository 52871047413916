@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.allWorkoutsTitle {
  color: theme-color('secondary');
  text-align: center;
  font-family: 'Bebas Neue';
  font-size: 66px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}
.noWorkoutsLabel {
  font-size: 44px;
  font-weight: 400;
  font-family: "Bebas Neue";
  color: #FFFFFF;
}
