@import 'src/asset/scss/shared/color';

.whiteBg {
  background-color: theme-color('ivory');
}

.trainingSeriesContainer {
  position: relative;
  width: 100%;
  height: 700px;
  box-sizing: border-box;
  margin-bottom: 77px;
  border-radius: 8px;
}

.trainingSeriesImage {
  width: 100%;
  height: 100%;
  object-position: top center;
  object-fit: cover;
  position: absolute;
  z-index: 80;
  top: 0;
  left: 0;
  border-radius: 8px;
}

.trainingSeriesContent {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 86px;
  box-sizing: border-box;
  border-radius: 8px;

  h2 {
    text-align: center;
    font-family: 'Bebas Neue';
    font-size: 88px;
    font-style: normal;
    font-weight: 400;
    line-height: 92px;
    text-transform: uppercase;
  }
}