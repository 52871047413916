.subFiltersContainer {
  padding: 0 1rem;
}

.chevronOpen {
  transform: rotate(90deg);
}
.chevronDisabled {
  opacity: 0.3;
}
.rootButton {
  padding: 5px 0;
}
