@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';


.sidebarContainer {
  max-width: 450px;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 2e8;
  background-color: theme-color('black');
  border-left: 1px solid theme-color('secondary-gray');
  right: 0;
  overflow-y: clip;
}
.sidebarContainerHidden  {
  visibility: hidden;
}
.sidebar {
  max-height: 100%;
}
.sidebarHeading {
  & > span {
    color: theme-color('secondary');
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  & > button {
    height: 36px;
    text-align: center;
    font-family: Bebas Neue;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
}
.sidebarFiltersContainer {
  overflow-y: scroll;
}
.submitFiltersContainer {
  padding-top: 1.25rem
}
