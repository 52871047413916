@import 'src/asset/scss/shared/color';

.container {
  padding: 20px 15px;
  border-radius: 6px;
  border: 1px solid theme-color('bright-grey');
  margin-bottom: 30px;
  height: 255px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  .awardImage {
    display: block;
    width: 100%;
    max-width: 140px;
    height: 100%;
    max-height: 120px;
    object-fit: contain;
    margin: 0 auto;
  }

  .disabled {
    opacity: 0.4;
  }

  h4, h5 {
    color: theme-color('ivory');
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    text-transform: capitalize;
  }
}