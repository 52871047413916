@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.trainingSeriesHeader {
  position: relative;
  height: 500px;
  border-radius: 8px;

  .trainingSeriesImage {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 80;
    object-fit: cover;
    object-position: top left;
    border-radius: 8px;
  }

  .trainingSeriesHeaderContent {
    z-index: 100;
    width: 100%;
    height: 100%;
    color: theme-color('white');
    border-radius: 8px;

    h1 {
      font-family: 'Bebas Neue';
      font-size: 44px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.32px;
      text-transform: uppercase;
    }

    p {
      color: theme-color('ivory');
      font-family: 'Inter';
      font-size: 21px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    .description {
      color: theme-color('mist');
      text-align: center;
      font-family: 'Inter';
      font-size: 21px;
      font-style: normal;
      font-weight: 300;
      line-height: 20px;
    }
  }
}
