@import 'src/asset/scss/shared/util';
@import 'src/asset/scss/shared/color';

$imageSize: 60px;
$imageBorderSize: 3px;


.container {
  width: 60px;
  display: inline-block;
  position: relative;

  .title {
    margin-top: 5px;
    text-align: center;
    @include font-size(12px);
    margin-bottom: 0;
  }

  .imageContainer {
    width: $imageSize;
    height: $imageSize;
    border: $imageBorderSize solid transparent;
    border-radius: $imageSize;
    position: absolute;
    left:0;
    top:0;


    &:global(.active) {
      border-color: theme-color('primary');
      background-color: rgba(theme-color('primary'), .4);
    }
  }

  img.image {
    width: $imageSize;
    height: $imageSize;
    border-radius: $imageSize;
  }
}
