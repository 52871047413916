@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.container {
  .navbar {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    max-width: 580px;
    margin: 0 auto;
  }

  :global(.nav-item) {
    :global(.nav-link) {
      color: theme-color('ivory');
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.3px;
      padding: 15px 0;
    }

    &.active {
      :global(.nav-link) {
        color: theme-color('mist');
        font-weight: 700;
        border-bottom: 1px solid theme-color('primary');
      }
    }
  }
}