@import 'src/asset/scss/shared/color';

.container {
  width: 74px;
  height: 25px;
  border-radius: 30px;
  background-color: theme-color('white');
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 7px 5px 12px;

  span {
    color: theme-color('primary-gray');
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: -0.45px;
  }
}

.notCompleted {
  background-color: theme-color('tertiary-grey');
  color: theme-color('primary-gray');
}