@import 'src/asset/scss/shared/color';

.awardIconContainer {
  img {
    width: 80px;
    height: 90px;
  }

  h4 {
    color: theme-color('ivory');
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 13px;
    margin-top: 31px;
  }

  .disabled {
    opacity: 0.33;
  }
}