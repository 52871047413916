@import 'src/asset/scss/shared/color';

.workoutPopup {
  .workoutPopupContent {
    border-radius: 8px;
    background-color: transparent;
  }
  .workoutPopupContainer {
    background-color: theme-color('black');
    border: 1px solid theme-color('secondary');
    border-radius: 8px;

    .imageContainer {
      max-width: 600px;
      width: 100%;
      margin: 6px auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;

      .coverImage {
        width: 100%;
        border-radius: 6px;
        object-fit: cover;
      }

      div {
        position: absolute;
        bottom: 0;
        padding: 15px 22px;
        margin-top: auto;
        z-index: 5;

        h2 {
          color: theme-color('ivory');
          font-family: 'Bebas Neue';
          font-size: 44px;
          font-weight: 400;
          line-height: 1em;
          letter-spacing: 1.4px;
          text-transform: uppercase;
        }

        h3 {
          color: theme-color('ivory');
          font-size: 21px;
          font-weight: 700;
          padding-top: 5px;
        }
      }
    }

    .workoutInfo {
      padding: 30px 21px;
      border-bottom: 1px solid theme-color('secondary-gray');
      display: flex;
      flex-direction: column;
      gap: 22px;
      color: theme-color('ivory');
      font-size: 14px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: -0.45px;

      .workoutIntensity {
        display: flex;
        align-items: center;
        text-transform: capitalize;
        width: fit-content;
        padding: 5px 15px 5px 10px;
        border-radius: 20px;
        gap: 5px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .low {
        background-color: theme-color('green-09');
      }

      .medium {
        background-color: theme-color('secondary');
      }

      .high {
        background-color: theme-color('danger-02');
      }

      & > div {
        display: flex;
        align-items: center;
        gap: 18px;
      }
    }

    .workoutDescription {
      padding: 30px 21px;
      margin: 0;
      border-bottom: 1px solid theme-color('secondary-gray');
      color: theme-color('ivory');
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
    }

    .workoutLinks {
      padding: 40px 30px 55px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;

      .favoriteIconContainer {
        width: 36px;
        height: 36px;
        background-color: theme-color('secondary-gray');
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          margin-top: 2px;
          width: 22px;
          height: 18px;
        }
      }

      .startWorkoutBtn {
        max-width: 200px;
        width: 100%;
        height: 58px;
        border: 1px solid theme-color('secondary');
        border-radius: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: theme-color('secondary');
        font-family: 'Bebas Neue';
        font-size: 20px;
        font-weight: 400;
        text-transform: uppercase;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }
}
