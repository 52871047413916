@import 'src/asset/scss/shared/color';

.workoutStyleSectionContainer {
  width: 100%;
  padding-top: 40px;
  padding-bottom: 50px;

  &.dark {
    background-color: theme-color('black');
    color: theme-color('secondary');
    .seeAll, .noWorkouts {
      color: theme-color('ivory');
    }
  }

  &.light {
    background-color: theme-color('ivory');
    color: theme-color('black');
    .seeAll, .noWorkouts {
      color: theme-color('black');
    }
  }

  .title {
    font-family: 'Bebas Neue';
    font-size: 66px;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
}
