@import 'src/asset/scss/shared/color';

.container {
  width: 34px;
  height: 34px;
  border-radius: 6px;
  border: 1px solid theme-color('mushroom');

  &:hover {
    border: 1px solid theme-color('secondary');
  }
}

.active {
  border: 1px solid theme-color('secondary');
}