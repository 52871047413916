@import 'src/asset/scss/shared/color';

.container {
  width: 100%;
  height: fit-content;
  background-color: theme-color('secondary-gray');
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 30px;

  h4 {
    color: theme-color('ivory');
    text-align: center;
    font-family: 'Bebas Neue';
    font-size: 66px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1em;
  }

  p {
    color: theme-color('mushroom');
    text-align: center;
    font-size: 21px;
    font-weight: 500;
    text-transform: capitalize;
    margin: 0;
  }
}