@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.whiteBg {
  background-color: theme-color('ivory');
}

.darkBg {
  background-color: theme-color('black');
}

.noWorkoutStyles {
  font-family: 'Bebas Neue';
  color: theme-color('white');
  font-size: 24px;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center;
}

.divider {
  width: 100%;
  height: 1px;
  color: theme-color('tertiary-grey');
}

.trainingSeriesContainer {
  .trainingSeriesTitle {
    color: theme-color('ivory');
    text-align: center;
    font-family: 'Bebas Neue';
    font-size: 66px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
}

.educationContainer {
  position: relative;
  width: 100%;
  height: fit-content;
  box-sizing: border-box;
  margin-bottom: 77px;
  border-radius: 8px;
  .educationImage {
    width: 100%;
    height: 100%;
    object-position: top left;
    object-fit: cover;
    position: absolute;
    z-index: 80;
    top: 0;
    left: 0;
    border-radius: 8px;
  }
}

.educationContent {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  gap: 120px;
  box-sizing: border-box;
  border-radius: 8px;

  h2 {
    text-align: center;
    font-family: 'Bebas Neue';
    font-size: 88px;
    font-style: normal;
    font-weight: 400;
    line-height: 92px;
    text-transform: uppercase;
  }
}
