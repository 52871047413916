@import 'src/asset/scss/shared/color';

.container {
  max-width: 150px;
  width: 100%;
  height: 56px;
  background: theme-color('primary-gray');
  border-radius: 2rem;
  padding-top: 0.5rem;
}

.icons {
  width: 40px;
  height: 40px;
  position: absolute;
}
