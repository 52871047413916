@import 'src/asset/scss/shared/color';

.container {
  .title {
    color: theme-color('secondary');
    font-family: 'Bebas Neue';
    font-size: 44px;
    font-weight: 400;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 40px;
  }

  .notFound {
    color: theme-color('white');
  }

  .profileFavoritesContainer {    
    .titleContainer {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: theme-color('ivory');
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;

        svg {
          width: 11px;
          height: 23px;
          margin-left: 10px;
        }
      }
    }
  }

  .connectContainer {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
    padding-top: 200px;
    padding-bottom: 50px;

    .connectContainerImage {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin-inline: auto;
      width: 100%;
      height: 100%;
      z-index: -1;
      object-fit: cover;
    }

    h3 {
      color: theme-color('white');
      text-align: center;
      font-family: 'Bebas Neue';
      font-size: 88px;
      font-weight: 400;
      line-height: 92px;
      text-transform: uppercase;
    }

    .connectBtn {
      width: 250px;
      height: 60px;
      border-radius: 30px;
      border: 1px solid theme-color('secondary');
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 60px auto 0;
      color: theme-color('secondary');
      font-size: 24px;
      font-family: 'Bebas Neue';
      font-weight: 400;
      text-transform: uppercase;
      cursor: pointer;
      text-decoration: none;
      transition: .3s background-color;

      &:hover {
        background-color: theme-color('secondary');
        color: theme-color('black');
      }
    }
  }

  .referralGratitudeContainer {
    .connectContainer {
      height: 550px;
  
      .connectContainerImage {
        opacity: 0.9;
      }
  
      .connectBtn {
        margin-top: 230px;
      }
    }
  }
}