@import 'src/asset/scss/shared/color';

.panel {
  background-color: theme-color('primary-gray');
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;
}

.header {
  font-family: Evogria;
  font-size: 22px;
  text-transform: uppercase;
  text-align: center;
  color: theme-color('primary');
  margin-bottom: 16px;
}

.text {
  font-family: GT Walsheim Pro;
  font-size: 12px;
  color: theme-color('white');
}

.number {
  font-family: Evogria;
  font-size: 13px;
  color: theme-color('white');
}
