@import 'src/asset/scss/shared/color';
@import 'src/asset/scss/shared/util';

.container {
  position: fixed;
  background-color: $body-bg;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2147483647;

  .rowMinHeight {
    min-height: 567px;
  }

  .center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .installAppImage {
    max-height: 567px;
  }

  .mobileFrame {
    height: 100%;
    width: 100%;
    background-size: contain, cover;
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: center;
  }

  h1 {
    color: theme-color('primary');
  }

  .largeMessageContainer {
    width: 100%;
    padding: 0 20px;

    h1 {
      @include font-size(40px);
    }

    p {
      @include font-size(32px);
    }
  }

  .mobileFrame {
    width: 279px;
    height: 549px;
    background-repeat: no-repeat;
    background-size: cover, contain;

    .smallMessageContainer {
      width: 80%;
    }
  }

  .downloadButton {
    width: 144px;
    margin-top: 20px;
  }

  .dismissButton {
    position: absolute;
    top:0;
    right:20px;
    padding: 10px;
    color: theme-color('tertiary-grey');
    @include font-size(40px);
    cursor: pointer;
  }
}
