.container {
  display: flex;
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  align-items: center;
  color: inherit;
  gap: 20px;

  .arrow {
    width: 11px;
    height: 23px;
  }
}