@import 'src/asset/scss/shared/color';

.container {
  border-top: 1px solid theme-color('secondary-gray');

  .personalFormGroupContainer {    
    .personalFormGroupLabel {
      color: theme-color('mist');
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 5px;
    }
  
    .personalFormGroupCol {
      font-family: 'Bebas Neue';
      font-size: 21px;
      background: theme-color('secondary-gray');
      border-radius: 6px;

      select, input {
        font-family: 'Bebas Neue';
        font-size: 21px;
        font-weight: 400;
        line-height: 43px;
        margin-left: 0;
        padding-left: 0;
        color: theme-color('light-gray');
      }

      .isInvalidReset {
        input, select {
          border-radius: 0;
        }
      }

      .feedbackReset {
        line-height: 1.3em;
        padding-top: 10px;
        padding-bottom: 5px;
      }
    }
  }
  
  .personalInfoSaveBtn {
    width: 110px;
    height: 40px;
    border-radius: 30px;
    border: 1px solid theme-color('secondary');
    background-color: transparent;
    color: theme-color('secondary');
    font-family: 'Bebas Neue';
    font-size: 16px;
    font-weight: 400;
  
    &:hover {
      background-color: theme-color('secondary');
      color: white;
    }
  }
  
  .notificationsContainer {
    border-top: 1px solid theme-color('secondary-gray');
  }
  
  .linksContainer {
    border-top: 1px solid theme-color('secondary-gray');
    border-bottom: 1px solid theme-color('secondary-gray');
  
    a {
      color: theme-color('mist');
      font-size: 14px;
      font-weight: 400;
  
      &:hover {
        color: theme-color('secondary');
      }
    }
  }
}