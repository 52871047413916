@import 'src/asset/scss/shared/color';

.container {
  position: relative;
  background-color: rgba(217, 217, 217, 0.2);
  border: 1px solid theme-color('bright-grey');
  height: fit-content;
  padding: 10px 20px;
  border-radius: 6px;
  margin-bottom: 30px;

  .score {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: theme-color('secondary-gray');
    font-size: 14px;
    font-weight: 600;
    line-height: 15px;
    letter-spacing: -0.5px;
  }

  .Bronze {
    background-color: theme-color('bronze');
  }

  .Silver {
    background-color: theme-color('mist');
  }

  .Gold {
    background-color: theme-color('gold');
  }

  .badgeImage {
    display: block;
    width: 70px;
    height: 80px;
    margin: 12px auto 28px;
  }

  h3 {
    text-align: center;
    color: theme-color('ivory');
    font-size: 18px;
    font-weight: 600;
    line-height: 13px;
  }
}